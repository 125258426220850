export default async function useDataOrFetch<T>(url: string) {
  const { data: nuxtData } = await useNuxtData<T>(url)
  const { data: fetchData, error, pending, refresh } = await useData<T>(url, { immediate: false })

  // If nuxtData is undefined or null, fetch the data
  if (nuxtData.value === undefined || nuxtData.value === null) {
    await refresh()
  }

  // Return the data from Nuxt context or from the fetch
  const data = computed(() => {
    return nuxtData.value || fetchData.value
  })

  return { data, error, pending, refresh }
}
