import type { FetchContext, FetchResponse } from 'ofetch'

type UseDataHooks = {
  onRequest?: () => void
  onResponseError?: (context: FetchContext & { error: Error }) => void
  onResponse?: (context: FetchContext & { response: FetchResponse<any> }) => Promise<void>
}

export default async function useData<T>(
  url: string,
  options: Parameters<typeof useFetch<T>>[1] & UseDataHooks = {},
  params?: Record<string, unknown>
) {
  const runtimeConfig = useRuntimeConfig()
  const { onRequest, onRequestError, onResponse, ...restOptions } = options

  const { data, pending, error, refresh } = await useFetch(url, {
    key: url,
    // baseURL: runtimeConfig.public.NUXT_URL,
    credentials: 'include',
    ...restOptions,
    onRequest,
    onRequestError,
    onResponse,
  })

  return { data, pending, error, refresh }
}
